import classNames from 'classnames';
import styles from './OrttoForm.module.scss';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

export interface OrttoFormProps {
	formHtmlCode: string;
	className?: string;
	displayButtonRight?: boolean;
	eventDisplayButtonRight?: boolean;
	selectAllLabel?: string;
	placeholderLabel?: string;
	inputFullWidth?: boolean;
}

const getCustomStyles = {
	control: (provided: any) => ({
		...provided,
		background: 'transparent',
		borderRadius: 0,
		borderColor: 'inherit',
	}),
	menu: (provided: any) => ({
		...provided,
		background: 'white',
		color: 'black',
		zIndex: 100,
	}),
	option: (provided: any, state: any) => ({
		...provided,
		color: state.isFocused ? 'black' : 'inherit',
	}),
	multiValue: (provided: any) => ({
		...provided,
		background: 'transparent',
	}),
	multiValueLabel: (provided: any) => ({
		...provided,
		color: 'inherit',
	}),
	multiValueRemove: (provided: any) => ({
		...provided,
		color: 'inherit',
	}),
	clearIndicator: (provided: any) => ({
		...provided,
		color: 'inherit',
	}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		color: 'inherit',
	}),
	placeholder: (provided: any) => ({
		...provided,
		color: 'inherit',
	}),
};

export const OrttoForm: React.FC<OrttoFormProps> = ({
	formHtmlCode,
	className,
	displayButtonRight,
	eventDisplayButtonRight,
	selectAllLabel,
	placeholderLabel,
	inputFullWidth,
}) => {
	const formRef = useRef<HTMLDivElement>(null);
	const [selectOptions, setSelectOptions] = useState<{ [key: string]: any[] }>({});
	const [selectElements, setSelectElements] = useState<{ [key: string]: HTMLElement }>({});
	const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: any[] }>({});

	useEffect(() => {
		// Remove query parameters from the URL to ensure Ortto processes the form submission correctly
		const url = new URL(window.location.href);
		url.search = '';
		window.history.replaceState({}, document.title, url.toString());

		const form = formRef.current;
		if (form) {
			const inputElements = form.querySelectorAll('input, textarea');
			const requiredInputElements = form.querySelectorAll('input[required], select[required], textarea[required]');
			const selectElements = form.querySelectorAll('select');
			const submitButton = form.querySelector('button[type="submit"]') as HTMLButtonElement;

			if (submitButton) {
				const buttonContainer = document.createElement('div');
				buttonContainer.className = styles.Ortto_textContainer;

				const buttonText = document.createElement('span');
				buttonText.className = styles.Ortto_ButtonText;
				buttonText.textContent = submitButton.innerText;

				const animatedButtonText = document.createElement('span');
				animatedButtonText.className = classNames(styles.Ortto_ButtonText, styles.Ortto_ButtonText___animationText);
				animatedButtonText.textContent = submitButton.innerText;

				buttonContainer.appendChild(buttonText);
				buttonContainer.appendChild(animatedButtonText);

				submitButton.innerHTML = '';
				submitButton.classList.add(styles.Ortto_Button);
				submitButton.appendChild(buttonContainer);
			}

			const checkRequiredFields = () => {
				let allFilled = true;
				if (requiredInputElements.length) {
					requiredInputElements.forEach((input: HTMLInputElement) => {
						if ((input.type === 'checkbox' && !input.checked) || (input.type !== 'checkbox' && !input.value)) {
							allFilled = false;
						}
					});
					if (allFilled) {
						submitButton.disabled = false;
						submitButton.classList.remove('disabled');
					} else {
						submitButton.disabled = true;
						submitButton.classList.add('disabled');
					}
				}
			};

			checkRequiredFields();

			const newSelectElements: { [key: string]: HTMLElement } = {};

			selectElements.forEach((selectElement) => {
				const options = Array.from(selectElement.options).map((option) => ({
					value: option.value,
					label: option.text,
				}));
				if (selectAllLabel) {
					options.unshift({ value: 'select_all', label: selectAllLabel });
				}
				setSelectOptions((prev) => ({ ...prev, [selectElement.name]: options }));

				const placeholder = document.createElement('div');
				placeholder.className = 'react-select-placeholder';
				selectElement.parentNode.insertBefore(placeholder, selectElement);
				selectElement.style.display = 'none';

				newSelectElements[selectElement.name] = placeholder;
			});

			setSelectElements(newSelectElements);

			inputElements.forEach((input) => {
				const inputContainer = input.closest('.ap3w-form-input');
				if (inputContainer) {
					const classes = classNames('ap3w-form-input', { 'ap3w-form-input___fullWidth': inputFullWidth }).split(' ');
					inputContainer.classList.add(...classes);
				}
				input.addEventListener('focus', handleInputFocus);
				input.addEventListener('input', checkRequiredFields);
			});

			return () => {
				inputElements.forEach((input) => {
					input.removeEventListener('focus', handleInputFocus);
					input.removeEventListener('input', checkRequiredFields);
				});
			};
		}
	}, [selectAllLabel]);

	const handleSelectChange = (name: string, selectedOptions: any) => {
		const form = formRef.current;
		if (form) {
			const selectElement = form.querySelector(`select[name="${name}"]`) as HTMLSelectElement;
			if (selectElement) {
				if (selectedOptions.some((option: any) => option.value === 'select_all')) {
					// Select all options
					const allOptions = selectOptions[name].filter((option) => option.value !== 'select_all');
					selectedOptions = allOptions;
				}
				Array.from(selectElement.options).forEach((option) => {
					option.selected = selectedOptions.some((selected: any) => selected.value === option.value);
				});
				setSelectedOptions((prev) => ({ ...prev, [name]: selectedOptions }));
			}
		}
	};

	function handleInputFocus(e: React.FocusEvent<EventTarget, Element> | Event) {
		const inputContainer = (e.target as HTMLInputElement).closest('.ap3w-form-input');
		if (inputContainer) {
			inputContainer.classList.add('focused');
		}
	}

	return (
		<div
			className={classNames(
				className,
				'ortto',
				{ ortto_displayButtonRight: displayButtonRight },
				{ ortto_eventDisplayButtonRight: eventDisplayButtonRight },
			)}
		>
			<div ref={formRef} dangerouslySetInnerHTML={{ __html: formHtmlCode }} />
			{Object.keys(selectOptions).map((name) => {
				const placeholder = selectElements[name];
				return (
					placeholder && (
						<div key={name} ref={(el) => el && placeholder.replaceWith(el)}>
							<Select
								name={name}
								options={selectOptions[name]}
								isMulti
								styles={getCustomStyles}
								onChange={(selectedOptions) => handleSelectChange(name, selectedOptions)}
								value={selectedOptions[name]}
								placeholder={placeholderLabel}
							/>
						</div>
					)
				);
			})}
		</div>
	);
};
