import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import Script from 'next/script';
import { Form } from 'ui/components/3-organisms/Form/Form';

export const FormFeature: React.FC<Content.Form> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const kicker = {
		firstWord: content.properties.kickerFirstWord,
		secondWord: content.properties.kickerSecondWord,
	};
	return (
		<>
			<Script strategy="beforeInteractive" id="ortto">
				{`	{window.ap3c = window.ap3c || {};
					var ap3c = window.ap3c;
					ap3c.cmd = ap3c.cmd || [];
					ap3c.cmd.push(function() {
						ap3c.init('Y5Qi-PBZ0OccFIOeaW5vdm9hcw', 'https://capture-api-us.ortto.app/');
						ap3c.track({v: 0});
					});
					ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };
					var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdn2l.ink/app.js";
					t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);}`}
			</Script>

			<Form
				{...content?.properties}
				kicker={kicker}
				bgColor={content?.properties.color}
				showImage={content.properties.showImage}
				layoutType={content.properties.layoutType}
				displayButtonRight={content?.properties?.displayButtonRight}
				selectAllLabel={dictionary?.getValue('Form.SelectAllFormLabel')}
				inputFullWidth={content?.properties?.inputFullWidth}
				placeholderLabel={dictionary?.getValue('Form.PlaceholderLabel')}
			/>
		</>
	);
};
